@import "~antd/dist/antd.css";

.loader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.map {
  width: 100%;
  height: 75vh;
}

header {
  position: fixed;
  z-index: 1;
  width: 100%;

  h1 {
    color: white !important;
    float: left;
    margin: 0 !important;
    margin-right: 20px !important;
    line-height: 64px !important;
  }
}

main {
  padding: 0 50px;
  margin-top: 64px;
  background-color: whitesmoke;

  > div {
    background-color: white;
    padding: 24px;
    min-height: 380px;
  }

  @media (max-width: 650px) {
    padding: 0;
    > div {
      padding: 12px;
    }
  }
}

.profile {
  float: right;
  span.ant-avatar {
    margin-right: 12px;
  }
}
